import Swiper, { Navigation, Pagination } from 'swiper';

// swiper
// eslint-disable-next-line no-new
new Swiper('.swiper-container-top', {
  slidesPerView: 'auto',
  centeredSlides: true,
  initialSlide: 0,
  loop: true,
  modules: [Navigation, Pagination],
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
  },
});
